import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { DashboardData } from '../survey/survey-new-dashboard/get-dashboard-data-model';
import { SurveyService } from '../survey/survey.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

interface CustomChartDataSets extends ChartDataSets {
  percentages?: string[]; // Optional property for percentages
}
@Component({
  selector: 'app-external-user-dashboard',
  templateUrl: './external-user-dashboard.component.html',
  styleUrls: ['./external-user-dashboard.component.css']
})

export class ExternalUserDashboardComponent implements OnInit {

  displayedColumns = [];
  tableData: any[] = [];
  surveyUid: string;
  getPartipantExcelResponse: any;
  getDashboardResponse: any;
  isSurveyDashboard: any;
  data: any;
  public dashboardData = new DashboardData();
  totalData: any;
  selectedLabel: string;
  showNextDashboard: boolean;
  public pieChartLabels: string[] = ['Pending', 'Completed'];
  public chartColors: any[] = [
    {
      backgroundColor: ['#e0542c', '#70c4fc'],
      borderColor: ['#FFF', '#FFF'],
      borderWidth: 1
    }
  ];

  public pieChartData: any;
  public pieChartType: string = 'pie';
  allDashboard: boolean = false;
  public pieChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || '';
            let value = context.raw;
            return `${label}: ${value}%`;
          }
        }
      },
      datalabels: {
        formatter: (value, ctx) => {
          return `${value}%`;
        },
        color: '#444',
        font: {
          weight: 'bold',
          size: 14
        }
      }
    }
  };

  public pieChartPlugins = [pluginDataLabels];

  topBarChartData: any[] = [];
  bottomBarChartData: any[] = [];

  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        gridLines: { display: false },
        ticks: { beginAtZero: true },
      }],
      yAxes: [{
        gridLines: { display: true },
        ticks: {
          beginAtZero: true,  // Ensures Y-axis starts from 0
          max: 100            // Limits Y-axis to a maximum value of 100
        },
        stacked: true  // Keeps stacked bars if you're using stacked chart
      }]
    },
    plugins: {
      datalabels: {
        color: 'white',
        formatter: (value, context) => {
          const dataset = context.dataset as CustomChartDataSets; // Cast dataset to include percentages
          const percentage = dataset.percentages ? dataset.percentages[context.dataIndex] : '';
          return percentage === '0%' ? '' : percentage; // Hide 0% labels
        },   // Change text color to white
        font: {
          weight: 'bold',
          size: 12,  // Customize font size if necessary
        },
        align: 'center',  // Align the label to the center
        anchor: 'center'  // Anchor the label in the middle of the bar
      }
    }
  };
  barChartLabels: string[] = ['column1', 'column2', 'column3', 'column4', 'column5', 'column6'];
  barChartData: CustomChartDataSets[] = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartColors: Color[] = [
    { backgroundColor: '#807c7c' }, { backgroundColor: '#a06444' }, { backgroundColor: '#ecbc0c' },
    { backgroundColor: '#588ccc' }, { backgroundColor: '#ff8404' }
  ];

  responseBars = [
    { class: 'strongly-disagree', key: 'stronglyDisagree', title: 'Strongly Disagree: ' },
    { class: 'disagree', key: 'disagree', title: 'Disagree: ' },
    { class: 'neither-aod', key: 'neitherAOD', title: 'Neither Agree nor Disagree: ' },
    { class: 'almost-agree', key: 'agree', title: 'Agree: ' },
    { class: 'strongly-agree', key: 'stronglyAgree', title: 'Strongly Agree: ' }
  ];

  dashboardRequest: { surveyUId: string; formFields: { index: number; label: string; usedForFilter: boolean; options: any[]; }[]; };
  dashboardLinkId: string;
  engagementDashboardLinkId: string;
  getEngagementDashboardResponse: any;
  invalidLink: boolean = false;
  getSurveyStatementResponse: any;
  getExcelResponse: any;

  constructor(private route: ActivatedRoute, private router: Router, private surveyService: SurveyService) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('dashboardLinkId')) {
        this.dashboardLinkId = params.get('dashboardLinkId');
        this.isSurveyDashboard = true;
        this.getDashBoard(this.dashboardLinkId);
        this.displayedColumns = ['no', 'level', 'completion-rate', 'pending', 'completed', 'total'];
      }
      else if (params.get('engagementDashboardLinkId')) {
        this.engagementDashboardLinkId = params.get('engagementDashboardLinkId');
        this.isSurveyDashboard = false;
        // this.getDashBoardEngagementScoreDashBoard(this.engagementDashboardLinkId);
    this.getEngagementDashBoard(this.engagementDashboardLinkId);

      }
    });
  }

  clearFilter() {
    this.selectedLabel = 'All';
    this.getDashboardData(this.dashboardRequest);
  }

  clearFilter1() {
    this.selectedLabel = 'All';
    this.getDashBoardEngagementScoreDashBoard(this.dashboardRequest);
  }  

  getDashBoard(dashboardLinkId) {
    this.surveyService.getDashBoardByLink(dashboardLinkId).subscribe(response => {
      this.getPartipantExcelResponse = response;
      this.surveyUid = this.getPartipantExcelResponse.surveyUId;
      this.getPartipantExcelResponse.formFields.push({
        index: 0,
        label: "All",
        usedForFilter: true,
        options: []
      });

      this.selectedLabel = "All";

      if (this.getPartipantExcelResponse.surveyUId !== null) {
        if (this.getPartipantExcelResponse.formFields.length > 0) {
          this.showNextDashboard = true;
        }
        this.dashboardRequest = {
          surveyUId: this.getPartipantExcelResponse.surveyUId,
          formFields: [{
            index: 0,
            label: "All",
            usedForFilter: true,
            options: []
          }]
        };
        this.getDashboardData(this.dashboardRequest);
      }

    }, error => {
      this.invalidLink = true;
      console.log(error);
    });
  }

  getDashboardData(obj) {
    this.surveyService.getDashboardForSurvey(obj).subscribe(response => {
      this.getDashboardResponse = response;
      this.tableData = this.getDashboardResponse.data;
      this.pieChartData = [this.getDashboardResponse.total.pendingPercent, this.getDashboardResponse.total.completedPercent];
      if (this.getDashboardResponse.data.length === 0) {
        this.allDashboard = true;
      } else {
        this.allDashboard = false;
      }
    },
      error => {
        this.invalidLink = true;
        console.log(error);
      });
  }

  getEngagementDashBoard(surveyuid) {
    this.surveyService.getEngagementDashBoardByLink(surveyuid).subscribe(response => {
      this.getExcelResponse = response;
      this.dashboardRequest = {
        surveyUId: this.getExcelResponse.surveyUId,
        formFields: [{
          index: 0,
          label: "All",
          usedForFilter: true,
          options: []
        }]
      };
      this.surveyUid = this.getExcelResponse.surveyUId;
      this.getDashBoardEngagementScoreDashBoard(this.dashboardRequest);
      this.getSurveyStatements(this.surveyUid);
      this.getExcelResponse.formFields.push({
        index: 0,
        label: "All",
        usedForFilter: true,
        options: []
      });
      this.selectedLabel = "All";
    })
  }

  getSurveyStatements(surveyuid: string) {
    this.surveyService.getSurveyResponseStatement(surveyuid).subscribe((response) => {
      this.getSurveyStatementResponse = response;

      // Map and prepare data for top statements
      if (this.getSurveyStatementResponse.topStatement.length > 0) {
        this.topBarChartData = this.getSurveyStatementResponse.topStatement.map(
          (statement) => this.mapToChartData(statement)
        );
      }

      // Map and prepare data for bottom statements
      if (this.getSurveyStatementResponse.bottomStatement.length > 0) {
        this.bottomBarChartData = this.getSurveyStatementResponse.bottomStatement.map(
          (statement) => this.mapToChartData(statement)
        );
      }
    });
  }

  mapToChartData(statement) {
    const totalResponses = statement.totalResponses || 1; // Avoid division by zero
    return [
      {
        value: (statement.stronglyAgree / totalResponses) * 100,
        color: '#807c7c',
      },
      {
        value: (statement.agree / totalResponses) * 100,
        color: '#a06444',
      },
      {
        value: (statement.neitherAOD / totalResponses) * 100,
        color: '#ecbc0c',
      },
      {
        value: (statement.disagree / totalResponses) * 100,
        color: '#588ccc',
      },
      {
        value: (statement.stronglyDisagree / totalResponses) * 100,
        color: '#ff8404',
      },
    ].filter((point) => point.value > 0); // Exclude fields with 0 value
  }

  onSelectionChange(event: any) {
    const selectedLabel = event.value;
    const selectedObject = this.getPartipantExcelResponse.formFields.find(field => field.label === selectedLabel);
    if (selectedObject) {
      const dashboardRequest = {
        surveyUId: this.surveyUid,
        formFields: [selectedObject]
      };
      this.getDashboardData(dashboardRequest);
    }
  }

  onSelectionChange1(event: any) {
    const selectedLabel = event.value;
    const selectedObject = this.getExcelResponse.formFields.find(field => field.label === selectedLabel);
    if (selectedObject) {
      const dashboardRequest = {
        surveyUId: this.surveyUid,
        formFields: [selectedObject]
      };
      this.getDashBoardEngagementScoreDashBoard(dashboardRequest);
    }
  }

  getDashBoardEngagementScoreDashBoard(dashboardLinkId) {
    this.surveyService.getDashBoardForEngagementScore(dashboardLinkId).subscribe(response => {
      this.getEngagementDashboardResponse = response;

      if (this.getEngagementDashboardResponse.engagementDatas) {
        const engagementDatas = this.getEngagementDashboardResponse.engagementDatas;

        // Set chart labels
        this.barChartLabels = engagementDatas.map(data => data.filter);

        // Prepare chart data
        this.barChartData = [
          {
            data: engagementDatas.map(data => parseFloat(data.disengaged.replace('%', '')) || 0),
            label: 'Disengaged',
            stack: 'Stack 0',
            percentages: engagementDatas.map(data => data.disengaged)
          },
          {
            data: engagementDatas.map(data => parseFloat(data.crashBurners.replace('%', '')) || 0),
            label: 'Crash & Burn',
            stack: 'Stack 0',
            percentages: engagementDatas.map(data => data.crashBurners)
          },
          {
            data: engagementDatas.map(data => parseFloat(data.honeymoonHamsters.replace('%', '')) || 0),
            label: 'Honeymooners & Hamsters',
            stack: 'Stack 0',
            percentages: engagementDatas.map(data => data.honeymoonHamsters)
          },
          {
            data: engagementDatas.map(data => parseFloat(data.almostEngaged.replace('%', '')) || 0),
            label: 'Almost Engaged',
            stack: 'Stack 0',
            percentages: engagementDatas.map(data => data.almostEngaged)
          },
          {
            data: engagementDatas.map(data => parseFloat(data.fullyEngaged.replace('%', '')) || 0),
            label: 'Engaged',
            stack: 'Stack 0',
            percentages: engagementDatas.map(data => data.fullyEngaged)
          }
        ];

      }
    });
  }
}
